import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import AboveFooterSection from '../components/AboveFooterSection'

import Layout from '../components/Layout'
import Spacing from '../components/partials/Spacing'

import { StaticImage } from 'gatsby-plugin-image'

const usecases = () => {
  const data = useStaticQuery(graphql`
    query UsecasesSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Usecases" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <section className="hero-margin">
        <div className="container-1">
          <div className="d-flex flex-wrap mt-3">
            <div className="col-md-6">
              <h1 className="pr-5 mr-4">
                Lenses is the full developer experience for any Apache Kafka
              </h1>
              <div className="pr-5 mobile-padding-reset">
                <p className="hero-description-dark pr-5">
                  Avoid multiple DIY tools. Fast-track to production with a
                  single solution for Kafka monitoring & observability, data
                  governance, security and low-code application development.
                </p>
                <div className="mt-2 roll-button">
                  <a
                    className="bg-red text-white"
                    href="/start/?s=usecases"
                    role="button"
                  >
                    <span data-hover="Try free">Try free</span>
                  </a>
                  <br />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <StaticImage
                className="img-fluid mx-auto d-block mobile-margin-top-half w-75"
                src="../resources/images/usecases/hero-image.svg"
                placeholder="tracedSVG"
                alt="Apache Kafka usecases"
              />
            </div>
          </div>
          <Spacing />

          <div className="d-flex flex-wrap justify-content-md-between justify-content-around mt-4 mobile-margin-top">
            <div className="col-md-3 col-sm-5 col-12 px-0 text-center mobile-bg-light-gray mobile-padding-bottom">
              <StaticImage
                className="img-fluid mx-auto d-block mobile-margin-top-half mobile-w-50"
                quality={100}
                src="../resources/images/usecases/monitoring.svg"
                placeholder="tracedSVG"
                alt="Kafka Monitoring"
              />
              <h2 className="paragraph-title pt-3 w-50 mobile-w-100 mx-auto">
                Kafka monitoring
              </h2>
              <p>
                Know whether your streaming platform, applications and real-time
                data are healthy.
              </p>
              <br />
              <a
                href="/usecases/kafka-monitoring/"
                className="primary-text"
                id="usecases-kafka-monitoring"
              >
                Learn more <i className="red-pointer-right"></i>
              </a>
            </div>
            <div className="col-md-3 col-sm-5 col-12 px-0 text-center mobile-padding-bottom">
              <StaticImage
                className="img-fluid mx-auto d-block mobile-margin-top-half mobile-w-50"
                quality={100}
                src="../resources/images/usecases/observability.svg"
                placeholder="tracedSVG"
                alt="Kafka Monitoring"
              />
              <h2 className="paragraph-title pt-3">
                Data observability & catalog
              </h2>
              <p>
                Cut out the Kafka commands you need to learn by exploring events
                using SQL, a Kafka UI and data catalog.
              </p>
              <a
                href="/usecases/apache-kafka-data-observability/"
                className="primary-text"
                id="usecases-data-observability"
              >
                Learn more <i className="red-pointer-right"></i>
              </a>
            </div>

            <div className="col-md-3 col-sm-5 col-12 px-0 text-center mobile-bg-light-gray mobile-padding-bottom">
              <StaticImage
                className="img-fluid mx-auto d-block mobile-margin-top-half mobile-w-50"
                quality={100}
                src="../resources/images/usecases/governance.svg"
                placeholder="tracedSVG"
                alt="Kafka self-service and governance"
              />
              <h2 className="paragraph-title pt-3">
                Kafka self-service & governance
              </h2>
              <p>
                Create a healthy, safe multi-tenant Kafka environment through
                developer data access controls.
              </p>
              <a
                href="/usecases/apache-kafka-governance/"
                className="primary-text"
                id="usecases-self-service-governance"
              >
                Learn more <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>

          <Spacing />

          <div className="d-flex flex-wrap justify-content-md-center justify-content-around mt-4 mr-5 mobile-margin-reset">
            <div className="col-md-3 col-sm-5 col-12 px-0 text-center mobile-padding-bottom">
              <StaticImage
                className="img-fluid mx-auto d-block mobile-margin-top-half mobile-w-50"
                quality={100}
                src="../resources/images/usecases/low-code.svg"
                placeholder="tracedSVG"
                alt="Low code Kafka streams"
              />
              <h2 className="paragraph-title pt-3">Low-code Kafka streams</h2>
              <p>
                Filter, aggregate and transform streaming data with SQL,
                deploying over your existing infrastructure.
              </p>
              <div className="mobile-margin-reset">
                <a
                  href="/usecases/kafka-streams/"
                  className="primary-text"
                  id="usecases-low-code-kafka-streams"
                >
                  Learn more <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>
            <div className="col-md-3 col-sm-5 col-12 px-0 offset-0 offset-md-2 text-center mobile-bg-light-gray mobile-padding-bottom">
              <StaticImage
                className="img-fluid mx-auto d-block mobile-margin-top-half mobile-w-50"
                quality={100}
                src="../resources/images/usecases/security.svg"
                placeholder="tracedSVG"
                alt="Kafka Monitoring"
              />
              <h2 className="paragraph-title pt-3">
                Kafka security & compliance
              </h2>
              <p>
                Control streaming platform operations with a unified security
                model, then send audits into your SIEM.
              </p>
              <a
                href="/usecases/apache-kafka-security/"
                className="primary-text"
                id="usecases-kafka-security"
              >
                Learn more <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <AboveFooterSection
        text={'Ready to get started with Lenses?'}
        ctaText={'Try now for free'}
        ctaLink="/start/"
      />
    </Layout>
  )
}

export default usecases
